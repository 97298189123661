import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/faq.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import ab1 from '../images/Group 7284-min.png';
import ab2 from '../images/Group 7285-min.png';
import ab3 from '../images/Frame 3572-min.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

 const Trade =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Engineering, Interior Remodeling & Construction Company - Jayco Construction</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>Trade Signal </h1>
          <br />
          <p>Receive daily live trading alerts, actionable market insights, and educational snippets that sharpen your skills. Join a community of traders striving for excellence and gain the edge you need to succeed.</p>
      
        </div>
     </div>
     </div>
    </section>
  
<section>

  <div className='faq__sec'>
    <div className='r__trade'>
    <div className='r__colt'>
<h2>Trade Signal</h2>
<br />
<p style={{fontSize:"20px",color:"white",lineHeight:"20px"}}>Receive daily live trading alerts, actionable market insights, and educational snippets that sharpen your skills. Join a community of traders striving for excellence and gain the edge you need to succeed.</p>
    </div>
    <br />
    <div className='r__colt'>
    <iframe style={{width:"100%",height:"80vh"}} src="https://discord.com/widget?id=1280811151487406140&theme=dark"  allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>     
    </div>
    </div>
 
  </div>
</section>


<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Trade;