import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'

import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import t2 from '../images/Shapes (2)-min.png';
import t3 from '../images/traing 2 1-min.png';
import t4 from '../images/BENEFIT-min.png';
import t5 from '../images/CURRENCY EXCHANGE-min.png';
import t6 from '../images/MONEY MANAGEMENT-min.png';
import sec3a from '../images/Icon_Placeholder (1)-min.png';
import sec3b from '../images/Icon_Placeholder (2)-min.png';
import sec3c from '../images/Icon_Placeholder (3)-min.png';
import sec3d from '../images/Icon_Placeholder (4)-min.png';
import sec3e from '../images/Icon_Placeholder (5)-min.png';
import sec3f from '../images/Icon_Placeholder-min.png';
import chec from '../images/check-box-min.png';
import person1 from '../images/ourteam2.png';
import media1 from '../images/Media1-min.png';
import media2 from '../images/Media2-min.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Expert Trading Alerts & Analysis - My Trade Signal</title>
      <meta name="description" content="Stay ahead in trading with 'My Trade Signal'—expert alerts and analysis for smarter decisions. Get timely signals and insights to boost your trading success!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      <script async src="https://www.launchpass.com/my-trade-signal/platinum/v2/embed.js"></script>      
      <script async src="https://www.launchpass.com/my-trade-signal/platinum/v2/embed.js"></script>  
         
      <script async src="https://www.launchpass.com/my-trade-signal/platinum/v2/embed.js"></script>      

      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1 style={{fontSize:"40px"}}>Empower your trading with precision alerts and expert insights, <span style={{color:"rgba(0, 208, 148, 1)"}}> tailored for your success.</span> </h1>
          <p>Receive daily live trading alerts, actionable market insights, and educational snippets that sharpen your skills. Join a community of traders striving for excellence and gain the edge you need to succeed.</p>
          <br />
          <button   > <a href="TradeSignal">Start Your 7-Day Free Trial Now</a> </button>
        </div>
     </div>
     </div>
    </section>
  
     <section className='t_section'>
       <div className='main__t'>
          <div className='row_t2'>
            <div className='col2_t'>
              <h2>Key Benefits of<span style={{color:"rgba(0, 208, 148, 1)"}}> MyTradeSignal</span>  </h2>
              
              <div className='row_t3'>
               <div className='col_t3a'>
               <img src={t4}></img>
                </div>
               <div className='col_t3b'>
                 <h3>Real-Time Trading Alerts 
                 </h3>
                 <p>Receive actionable trade setups with specific entry and exit points, delivered daily. 
                 Stay ahead of the market with timely notifications designed to maximize your trading potential. </p>
              </div>

              </div>
              <div className='row_t3'>
               <div className='col_t3a'>
               <img src={t5}></img>
                </div>
               <div className='col_t3b'>
                 <h3>Daily Market Insights</h3>
                 <p>Gain focused market analysis that informs and complements each trading alert. 
                 Understand market trends and movements to make smarter, more informed decisions.</p>
              </div>

              </div>
              <div className='row_t3'>
               <div className='col_t3a'>
               <img src={t6}></img>
                </div>
               <div className='col_t3b'>
                 <h3>Continuous Learning</h3>
                 <p>Improve your trading skills with quick educational tips and strategies included with each alert. 
                 Grow your knowledge over time with insights from seasoned trading professionals.</p>
              </div>

              </div>
            </div>
            <div id='pic__hid' className='col2_t'>
            <img  src={t2}></img>
            <img  style={{marginTop:"-98%"}} src={t3}></img>
            </div>
          </div>
       </div>
    </section>

<br /><br />


<section style={{background:""}} className='t_section'>
  <div className='sec3_div' >
   <h2 >What We <span style={{color:"rgba(0, 208, 148, 1)"}}>offer</span> </h2>
   <br />
  <div className='sec3_row'>
   <div className='sec_col3'>
    <img src={sec3d} alt="" />
    <h3>Daily Live Trading Alerts</h3>
    <p>Receive daily, actionable trading alerts with clear entry and exit points to capitalize on market opportunities. </p>
   </div>
    <br />
   <div className='sec_col3'>
    <img src={sec3e} alt="" />
    <h3>Market Insights</h3>
    <p>Benefit from brief, focused market analysis that complements each trading alert, helping you understand market conditions.</p>
   </div>
    <br />
   <div className='sec_col3'>
    <img src={sec3f} alt="" />
    <h3>Educational Snippet</h3>
    <p>Enhance your trading skills with educational tips and strategies delivered alongside every alert. </p>
   </div>

  </div>
<br />
  <div className='sec3_row'>
   <div className='sec_col3'>
    <img src={sec3a} alt="" />
    <h3>Private Community Access</h3>
    <p>Join our exclusive Discord/Slack community to discuss daily trades, share strategies, and learn from fellow traders.  </p>
   </div>
    <br />
   <div className='sec_col3'>
    <img src={sec3b} alt="" />
    <h3>Interactive Webinars</h3>
    <p>Participate in live sessions hosted by trading experts to stay updated on the latest strategies and market trends. </p>
   </div>
    <br />
   <div className='sec_col3'>
    <img src={sec3c} alt="" />
    <h3>One-on-One Mentorship 
    </h3>
    <p>Receive personalized guidance and advice from our team of experienced traders to refine your trading approach. </p>
   </div>

  </div>
  </div>
</section>

<br /><br />
<section className='sec4' >

<div className='sec4_div'>
<h2>Pricing </h2>
<br /><br />

<div className='sec4_row'>


<div  className='sec4_col'>

<h3> Get your premium membership for only <span id='num'>$99.99</span> </h3>
 <hr />
  <div className='sec4_row2'>
  <div className='sec4_row2_cola'>
    <img src={chec} alt="" />
  </div>
  <div className='sec4_row2_colb'>
  <p>Take your trading to the next level with unlimited daily trade alerts! For just $99/month, you’ll have the tools and insights to maximize your profits. Plus, start today with a 5-day free trial—no risk, just reward! </p>
  </div>
  
  </div> 
  
  <br />
 

  <button >
  <a href="TradeSignal">Get Profitable Now</a>
    </button>

</div>
</div>


</div>
</section>
<br /><br /><br />
<section className='sec5'>
<div  className='sec_div5'>
  <h2><span style={{color:"rgba(0, 208, 148, 1)"}}> Platform </span>Roadmap</h2>
<br />
  <div  className='row__sec5'>
  &nbsp;    &nbsp;    &nbsp;          &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;&nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;
    <div className='col___sec5'>
      <h3>Launch Phase</h3>
      <p>Roll out daily live trading alerts and market insights to the first wave of users.</p>
    </div>
    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;
    <div className='col___sec5'>
      <h3>Community Engagement</h3>
      <p>Build a thriving private community on Discord/Slack for members to share insights and strategies.</p>
      
    </div>
    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;

    <div className='col___sec5'>
      <h3>Expansion of Educational Content</h3>
      <p>Introduce more comprehensive educational resources, including video tutorials and eBooks.</p>
   
    </div>
  </div>
<br />
  <div style={{}} className='row__sec5'>
   
    <div className='col___sec5'>
      <h3>Introduction of Webinars</h3>
      <p>Host regular interactive webinars to discuss market trends and trading strategies. </p>
     </div>
    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;
    &nbsp;
    <div className='col___sec5'>
      <h3>Personalized Mentorship Program</h3>
      <p>Launch a mentorship program offering one-on-one sessions with expert traders. </p>
      
    </div>

    &nbsp;    &nbsp;    &nbsp;    &nbsp;    &nbsp;

    <div className='col___sec5'>
      <h3>AI-Enhanced Trading Alerts 
      </h3>
      <p>Implement advanced AI technology to provide even more accurate and personalized trading signals. </p>
    </div>
  </div>
</div>
</section>
<br /><br />
<section style={{display:"none"}} className='sec__6'>
  <div className='sec__div6'>
    <h2>Meet with <span style={{color:"rgba(0, 208, 148, 1)"}}>our team</span> </h2>
    <br /><br />
   <div className='sec__row6'>
   <div className='sec6__col'>
    <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>
   </div>
   &nbsp;
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   </div>
<br />
   <div className='sec__row6'>
   <div className='sec6__col'>
    <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>
   </div>
   &nbsp;
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   </div>
  </div>
</section>
<br /><br />
<section className='sec__8'>
<div className='div__8'>
   <h2>Frequently Asked Questions  <span style={{color:"rgba(0, 208, 148, 1)"}}>(FAQs) </span> </h2>
   <br />
   <div className='row__sec8'>
   <div className='col__sec8'>
    <details>
  <summary data-aos="fade-right">What is MyTradeSignal?</summary>
  <div>
  <p>MyTradeSignal is a trading platform offering daily live trading alerts, market insights, and educational resources to help traders make informed decisions. </p>
  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">How can I start my free trial? 
</summary>
  <div>
  <p>Click on "Start Your 7-Day Free Trial Now," enter your details, and get instant access to our platform. You can cancel anytime before the trial ends to avoid charges. </p>
  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">Why do I need to provide credit card information for the free trial? 
</summary>  <div>
<p>Credit card information is required to activate the trial, but you won't be charged if you cancel before the trial period ends. </p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">What do the daily trading alerts include?</summary>  <div>
<p>Alerts include specific trade setups with entry and exit points, daily market insights, and educational tips to help improve your trading skills. </p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">How do the market insights help me? 
</summary>  
<div style={{fontWeight:"normal"}}>
 <p>Market insights provide a brief analysis of market conditions and trends, helping you understand the rationale behind each trading alert. </p>
   
  </div>
</details>
<br />
    </div>    <div className='col__sec8'>
    <details>
  <summary data-aos="fade-right">Can I access the private community during the free trial?</summary>
  <div>
  <p>Yes, trial users get full access to our private community, where they can discuss trades and strategies with other members.  </p>

  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">What happens after the free trial ends? 
</summary>
  <div>
  <p>After the trial, you can choose the daily plan to continue receiving alerts and access to all features for just $10 per trading day.</p>
  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">Are there any hidden fees?</summary>  <div>
<p>No, our pricing is straightforward. You pay $10 per trading day, billed daily or weekly, with no hidden fees.</p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">What kind of educational content is provided?</summary>  <div>
<p>We offer educational snippets with each alert, along with more comprehensive tutorials, articles, and webinars. </p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">How can I get personalized mentorship?</summary>  <div>
<p>We offer one-on-one mentorship sessions with our expert traders, which will be available soon as part of our service offerings.</p>
</div>
</details>
<br />
    </div>
   </div>
</div>
</section>
<br /><br />
<section style={{background:"rgba(0, 21, 15, 1)",display:"none"}} className='sec__9'>
  <div className='div__9'>
   <h2>Popular Trading <span style={{color:"rgba(0, 208, 148, 1)"}}>Articles</span> </h2>
   <br /><br />
  <div className='sec__row9'>
   <div className='col__sec9'>
      
        <img src={media1} alt="" />
        <h3>Top 10 Tips for Successful Trading.</h3>
        <p>Follow these 10 trading tips: research, diversify, stay disciplined, risk smartly, stay informed, trade small, stay patient, track performance, ignore emotions, and learn from failures.</p>
         <a href="#">Read more</a>
      </div>
      <div className='col__sec9'>
      
        <img src={media2} alt="" />
        <h3>Top 10 Tips for Successful Trading.</h3>
        <p>Follow these 10 trading tips: research, diversify, stay disciplined, risk smartly, stay informed, trade small, stay patient, track performance, ignore emotions, and learn from failures.</p>
         <a href="#">Read more</a>
      </div>   
  </div>
  <div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    </div>
  </div>
  </div>
  </div>
</section>
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />
    </div>
  )
}
export default Home;