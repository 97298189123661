import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/AboutUs.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import ab1 from '../images/Group 7284-min.png';
import ab2 from '../images/Group 7285-min.png';
import ab3 from '../images/Frame 3572-min.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

 const About =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>About Us | MyTradeSignal - Expert Trading Insights</title>
      <meta name="description" content="Discover MyTradeSignal's expert trading insights and market strategies. Learn about our team and how we help you achieve trading success. Join us today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>About Us </h1>
          <p>Are you tired of your currency not going as far as it used to? Try tradingo and multiply your money! With their user-friendly interface and expert market analysis, you'll be earning more in no time.</p>
          <br />
          <button>Let’s start a Trial</button>
        </div>
     </div>
     </div>
    </section>
  
  <section className=''>
  <div className='ab_div1'>
   <div className='ab__rowa'>
    <div className='col__a'>
      <h3>ABOUT US</h3>

      <br />
      <p>At MyTradeSignal, we believe that every trader, regardless of experience level, deserves access to powerful tools and insights that can help them navigate the complexities of the market. Born out of a passion for trading and a commitment to helping others succeed, we have created a platform that empowers traders with real-time alerts, in-depth market analysis, and a supportive community. 
<br />
Our journey began with a simple idea: to demystify trading and make it accessible to everyone. We saw that many traders struggled with understanding market movements and often missed out on opportunities due to a lack of timely information. That’s why we developed MyTradeSignal, a platform designed to provide clear, actionable insights that traders can use to make informed decisions. </p>
    </div>
    <div className='col__a'>
      <img src={ab1} alt="" />
    </div>
   </div>
 </div>
  </section>
<br /><br /><br />
<section>
<div className='ab_div1'>

   <div className='ab__rowa'>
   <div className='col__a'>
    <img src={ab2} alt="" />
  </div>
    <div className='col__a'>
      
      <h3>WHAT WE DO</h3>
      <br />
      <p>We provide daily live trading alerts that highlight potential opportunities in the market, complete with specific entry and exit points. Our expert analysis and educational snippets help you not only act on these alerts but also understand the market forces behind them. By combining real-time insights with a supportive trading community, we ensure that you have everything you need to thrive in today’s fast-paced trading environment. </p>
  </div>

  </div>
  </div>
</section>
<br /><br /><br />
<section>
<div className='ab_div1'>

   <div className='ab__rowa'>
    <div className='col__a'>
      
      <h3>WHY US</h3>
      <br />
       <p>We differentiate ourselves through our commitment to real-time, actionable information and a community-first approach. At MyTradeSignal, we are more than just a trading platform; we are a partner in your trading journey. Our focus is on providing value every single day, helping you make better trading decisions and grow your skills with every alert. 
</p>
  </div>
  <div className='col__a'>
    <img src={ab3} alt="" />
  </div>

  </div>
  </div>
</section>
<br /><br />
<section>
  <div  className='ab_div1'>
<h2 style={{textAlign:"center"}}>Mission, Vission & Core Values</h2>
<br />
<div className='cril'>
  <h2>Our Mission</h2>
  
  <br />
  <p>To empower traders of all levels with the tools, insights, and community support they need to make smarter, more profitable trading decisions. </p>
</div>

<div className='id_cir'>
<div className='cirl1'>
  <h2>MISSION </h2>
</div>
</div>



<div className='rowww'>
 
  <div className='col__circle'>

  <div className='id_cir2'>
    
<div className='cirl1'>
  <h2>VISION</h2>
</div>
</div>
<br />
  <div className='crila'>
  <h2>Our Vision</h2>
  
  <br />
  <p>To be the leading trading platform that transforms how individuals approach the markets by offering real-time insights and fostering a community of informed and successful traders. </p>
</div>
    
</div>

<div className='col__circle'>

<div id='mg' className='id_cir2'>
<div className='cirl1'>
<h2>MISSION</h2>
</div>
</div>
<br />
<div className='crila'>
<h2>Our Mission</h2>

<br />
<p>To be the leading exchange in Nigeria, offering our services at optimum price while availing our customers the benefit of enjoying instant and seamless payment.</p>
</div>
  
</div>
</div>

</div>


</section>
<br /><br />
<section className='sec__6' style={{display:"none"}}>
  <div className='sec__div6'>
    <h2>Meet with <span style={{color:"rgba(0, 208, 148, 1)"}}>our team</span> </h2>
    <br /><br />
   <div className='sec__row6'>
   <div className='sec6__col'>
    <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>
   </div>
   &nbsp;
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   </div>
<br />
   <div className='sec__row6'>
   <div className='sec6__col'>
    <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>
   </div>
   &nbsp;
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   <div className='sec6__col'>
   <div className='card_bx'>
    <h3>Shamika Thompson</h3>
    <p>Trade Ninja</p>
    </div>

   </div>
   </div>
  </div>
</section>
<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default About;