import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/blog.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';

import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';
import bg1 from '../images/Media1-min.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Blog =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Latest Stock Market Insights & Trading Tips | MyTrade Signal</title>
      <meta name="description" content="Discover the latest stock market insights and expert trading tips with MyTrade Signal. Stay ahead with our up-to-date analysis and actionable strategies."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>Blog </h1>
          <p>Are you tired of your currency not going as far as it used to? Try tradingo and multiply your money! With their user-friendly interface and expert market analysis, you'll be earning more in no time.</p>
          <br />
          <button>Let’s start a Trial</button>
        </div>
     </div>
     </div>
    </section>
  
<section>
<div className='blog__seca'>
 <div className='blog_row'>
  <div className='blog__col'>
    <img src={bg1} alt="" />
    <h2>Pietrangelo hopes he'll sign contract to remain with Blues</h2>
    <p>By Derrick Henry</p>
  </div>
  <div className='blog__col'>
    <img src={bg1} alt="" />
    <h2>Pietrangelo hopes he'll sign contract to remain with Blues</h2>
    <p>By Derrick Henry</p>
  </div>
  <div className='blog__col'>
    <img src={bg1} alt="" />
    <h2>Pietrangelo hopes he'll sign contract to remain with Blues</h2>
    <p>By Derrick Henry</p>
  </div>
 </div>
 <br />
 <div className='blog_row'>
  <div className='blog__col'>
    <img src={bg1} alt="" />
    <h2>Pietrangelo hopes he'll sign contract to remain with Blues</h2>
    <p>By Derrick Henry</p>
  </div>
  <div className='blog__col'>
    <img src={bg1} alt="" />
    <h2>Pietrangelo hopes he'll sign contract to remain with Blues</h2>
    <p>By Derrick Henry</p>
  </div>
  <div className='blog__col'>
    <img src={bg1} alt="" />
    <h2>Pietrangelo hopes he'll sign contract to remain with Blues</h2>
    <p>By Derrick Henry</p>
  </div>
 </div>
</div>

</section>


<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Blog;