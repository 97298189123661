import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from '../pages/Home';
import About from '../pages/About';
import Faq from '../pages/Faq';
import Contact from '../pages/Contact';
import Blog from '../pages/Blog';
import Privacy from '../pages/Privacy';
import Features from '../pages/Features';
import Testimonial from '../pages/Testimonal';
import Trade from '../pages/Trade';
import Discord from '../pages/Discord';



const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Navigate to='/Home'/>} />
      <Route path='home' element={<Home/>} />
      <Route path='about' element={<About/>} />
      <Route path='Faq' element={<Faq/>} />
      <Route path='Contact' element={<Contact/>} />
      <Route path='Blog' element={<Blog/>} />
      <Route path='Privacy' element={<Privacy/>} />
      <Route path='Features' element={<Features/>} />
      <Route path='Testimonial' element={<Testimonial/>} />
      <Route path='Trade' element={<Trade/>} />
      <Route path='TradeSignal' element={<Discord/>} />
    </Routes>
    
  )
}
export default Routers;