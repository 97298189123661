import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/faq.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';

import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

 const Privacy =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Privacy Policy | My Trade Signal Security & Data</title>
      <meta name="description" content="Protect your data with our Privacy Policy. Learn how My Trade Signal secures your information and ensures your privacy. Read our commitment to data safety."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1>Privacy Policy </h1>
          <p>Are you tired of your currency not going as far as it used to? Try tradingo and multiply your money! With their user-friendly interface and expert market analysis, you'll be earning more in no time.</p>
          <br />
          <button>Let’s start a Trial</button>
        </div>
     </div>
     </div>
    </section>
  
<section>
  <div className='privacy__sec'>
   <h2>Disclaimer</h2>
<p>My Trade Signal LLC does not give stock market advice and is not an investment advisor. All information provided is strictly opinions for informational and educational purposes. Trading has large potential rewards but also comes with inherent risks. It is strongly recommended that anyone considering participating in the stock market conduct research and understand all risks. All content on this discord server is information of a general nature and does not address the circumstances of any particular individual or entity. Nothing in the discord server constitutes professional and/or financial advice, nor does any information on this discord server constitute a comprehensive or complete statement of the matters discussed or the law relating thereto. By accessing our discord service, you agree to all such terms, conditions, notices, and accept full responsibility for your own profit and losses. Participants agree and accept that under no circumstance, My Trade Signal LLC would be held liable for any losses or damages as a result of your reliance on information provided by My Trade Signal LLC.
  <br /><br />
  Included but not limited to all materials and content published on the discord server are protected by copyright and are owned or controlled by or licensed to My Trade Signal LLC, or the party listed as the provider of the materials or software. Unauthorized copying, reproduction, republishing, uploading, posting, transmitting, or duplicating of any of the material is prohibited. You may access and use such content strictly for personal, noncommercial, and informational purposes. If you are found using the content provided here for any other purpose, you will be infringing on intellectual property rights of My Trade Signal LLC, and legal action will be taken.
  <br /><br />
  There will be days that My Trade Signal LLC will not provide service or post anything on the discord server throughout the year due to vacation, sickness, lack of market opportunities, and unforeseeable events. The stock market’s conditions determine the amount of content provided. There is no guarantee of trades every day unless an opportunity is presented. Everything discussed on the discord server is for educational and informational purposes. Actions taken with the information provided are 100% your responsibility.
  <br /><br />
  All content is owned and managed by My Trade Signal LLC. Your use of any and all material provided by and within the My Trade Signal discord server is owned exclusively by My Trade Signal LLC. By utilizing this material and discord server, you have agreed to all terms and conditions provided herein. Under federal anti-discrimination laws, businesses can refuse service to any person for any reason, unless the business is discriminating against a protected class. My Trade Signal LLC reserves the right to refuse service to anyone. There are no refunds after your payment has been accepted and processed except with respect to any statutory warranties or guarantees that cannot be excluded or limited by law. If you wish to cancel your subscription to the discord server, you have to send a cancellation notice to My Trade Signal LLC, and you will have access to the discord server until your renewal date unless specified. No partial refunds will be provided.
</p>
     </div>
</section>

<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Privacy;