import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/faq.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import ab1 from '../images/Group 7284-min.png';
import ab2 from '../images/Group 7285-min.png';
import ab3 from '../images/Frame 3572-min.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

 const Discord =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Engineering, Interior Remodeling & Construction Company - Jayco Construction</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

  
<section>
<br /><br /><br /><br />
  <div className='faq__sec'>
    <div className='r__trade'>
    <div className='r__colt'>
      <h2 id='fntt' >Ready to Transform  <span style={{color:"rgba(0, 208, 148, 1)"}}>Your Trading?</span></h2>
     <br />
      <p id='fttt' >Welcome to My Trade Signal! Gain exclusive access to premium stock and options trading signals, market insights, and a community of traders just like you – all in one place.</p>
<br />
<h3 id='ft3' >7-Day Free Trial</h3>
<br />
<p id='fttt' >Not sure yet? Take advantage of our 7-day free trial! Explore our platform, interact with our community, and see the impact of professional signals on your trading.
<br /><br />
Your journey toward smarter trading starts now. Try My Trade Signal with zero risk for 7 days. After that, it’s just <span style={{color:"rgba(0, 208, 148, 1)"}}>$99.99/month</span>  to stay connected, informed, and ready to act! <br />
<br />
<span style={{fontWeight:"700",display:"flex",alignItems:"center"}}>Click to Join the Community and Start Your Free Trial &nbsp; <i class='bx bx-right-arrow-alt'></i> </span> </p>
    </div>
    <br /><br />
    <div className='r__colt'>
    <iframe style={{width:"100%",height:"73vh"}} src="https://discord.com/widget?id=1280811151487406140&theme=dark"  allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>     
    </div>
    </div>
 
  </div>
</section>



  <br /><br />
  <br /><br />
    </div>
  )
}
export default Discord;