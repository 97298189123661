import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/testo.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';

import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';
import p1 from '../images/Div.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Testimonial =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>How about: Customer Testimonials | My Trade Signal Success Stories</title>
      <meta name="description" content="Discover real success stories with My Trade Signal. Read customer testimonials and see how our service has transformed trading strategies for many!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col'>
          <h1 style={{fontSize:"40px"}}>Hear from our traders how MyTradeSignal has transformed their trading journey </h1>
        </div>
     </div>
     </div>
    </section>
  
<section>
 <div className='testio'>
  <div className='test__row'>
<div className='test__col'>
<p>"MyTradeSignal has completely changed the way I trade. The alerts are spot-on, and I've seen consistent gains!"</p>
<br />
<div className='test_row2'>
  <div className='test__col2a'>
    <h3>— Alex T.,</h3>
    <p>Professional Trader</p>
  </div>
  <div className='test__col2a'>
    <img src={p1} alt="" />
  </div>
</div>
</div>
<br/>
<div className='test__col'>
<p>"The daily market insights are invaluable. They help me understand the market trends and make informed decisions."</p>
<br />
<div className='test_row2'>
  <div className='test__col2a'>
    <h3>— Rachel M.,</h3>
    <p>Day Trader </p>
  </div>
  <div className='test__col2a'>
    <img src={p1} alt="" />
  </div>
</div>
</div>
  </div>
<br />
<div className='test__row'>
<div className='test__col'>
<p>"I love the personalized alerts. They save me time and have improved my trading accuracy significantly."</p>
<br />
<div className='test_row2'>
  <div className='test__col2a'>
    <h3>— David L.,</h3>
    <p>Options Trader</p>
  </div>
  <div className='test__col2a'>
    <img src={p1} alt="" />
  </div>
</div>
</div>
<br/>
<div className='test__col'>
<p>"The one-on-one mentorship has provided me with insights I couldn't find anywhere else. It's like having a personal trading coach!"</p>
<br />
<div className='test_row2'>
  <div className='test__col2a'>
    <h3>—Emily S.,</h3>
    <p>Advanced Trader 

</p>
  </div>
  <div className='test__col2a'>
    <img src={p1} alt="" />
  </div>
</div>
</div>
  </div>
<br />
<div className='test__row'>
<div className='test__col'>
<p>"As a beginner, I felt overwhelmed with trading, but MyTradeSignal's educational tips have made learning easy and fun."</p>
<br />
<div className='test_row2'>
  <div className='test__col2a'>
    <h3>— James P.,</h3>
    <p>New Trader </p>
  </div>
  <div className='test__col2a'>
    <img src={p1} alt="" />
  </div>
</div>
</div>
<br/>
<div className='test__col'>
<p>"Being part of the MyTradeSignal community has been a game-changer. The support and knowledge shared here are incredible."</p>
<br />
<div className='test_row2'>
  <div className='test__col2a'>
    <h3>— Samantha K.,</h3>
    <p>Swing Trader </p>
  </div>
  <div className='test__col2a'>
    <img src={p1} alt="" />
  </div>
</div>
</div>
  </div>

 </div>
</section>


<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Testimonial;