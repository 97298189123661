import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/feature.css'
import {Helmet}from"react-helmet";
import t1 from '../images/tr2.png';
import fea from '../images/Vector (18)-min.png';
import fea2 from '../images/Group (5)-min.png';
import fea3 from '../images/Group (6)-min.png';
import feb1 from '../images/feb1.png';
import feb2 from '../images/feb2.png';
import feb3 from '../images/feb3.png';
import feb4 from '../images/feb4.png';
import feb5 from '../images/feb5.png';
import bit from '../images/bitcoin_btc_0003-Recovered 1-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

 const Features =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>TradeSignal Features: Boost Your Trading with Key Tools</title>
      <meta name="description" content="Unlock trading success with TradeSignal's key features: advanced analytics, real-time alerts, and customizable tools. Boost your trading strategy today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
     <div className='main__t'>
     <div className='row_t'>

        <div className='t_col'>
          <img src={t1}></img>
        </div>
        <div className='t_col' style={{width:"80%",margin:"auto"}}>
          <h1 style={{lineHeight:"80px"}}>Features of <br /> MyTrade Signal  </h1>
          
          <button>Let’s start a Trial</button>
        </div>
     </div>
     </div>
    </section>

  
<section>
<div className='feature__main'>
  <h2>Features of MyTradeSignal </h2>
  <br />
<dir className="feature__row">
  <div className='feature_col'>
    <h2>Daily Live Trading Alerts</h2>
    <p>Get real-time trading alerts that provide specific entry and exit points for stocks, options, and more. Our alerts are designed to be actionable and timely, helping you capitalize on market opportunities as they arise. </p>
  </div>
  <br />
  <div className='feature_col'>
    <h2>Focused Market Insights</h2>
    <p>Stay ahead with brief yet comprehensive daily market analysis. Our insights give you a clear view of market trends and potential movements, enabling you to make informed trading decisions every day.</p>
  </div>
</dir>
<br />
<dir className="feature__row">
  <div className='feature_col'>
    <h2>Educational Snippets</h2>
    <p>Enhance your trading skills with quick, actionable educational tips delivered with each alert. These snippets are designed to help you understand the rationale behind each trade and continuously improve your trading knowledge.</p>
  </div>
  <br />
  <div className='feature_col'>
    <h2>Private Community Access 
    </h2>
    <p>Join a vibrant and exclusive community of like-minded traders on Discord/Slack. Share strategies, discuss market trends, and learn from others in a supportive environment that encourages growth and collaboration.</p>
  </div>
</dir>
<br />
<dir className="feature__row">
  <div className='feature_col'>
    <h2>Interactive Webinars and Workshops</h2>
    <p>Participate in regular webinars hosted by seasoned trading experts. These sessions cover the latest market trends, trading strategies, and provide answers to your trading-related questions in real time. </p>
  </div>
  <br />
  <div className='feature_col'>
    <h2>One-on-One Mentorship 
    </h2>
    <p>Receive personalized guidance from our expert traders to refine your trading strategies. Our mentorship program is tailored to your needs, offering valuable insights and support to help you succeed. </p>
  </div>
</dir>
</div>
</section>


<br /><br />

<section>
  <div className='feature__main '>
    <h2>Why Choose MyTradeSignal </h2>
   <br />
    <div className='feature__row'>
    <div className='feature__col2'>
      <img src={fea} alt="" />
      <h3>Tailored Trading Experience 
      </h3>
      <p>MyTradeSignal isn’t just another trading platform. We focus on delivering personalized alerts and educational content tailored to your trading style, ensuring that you receive relevant and actionable information. </p>
    </div>
<br />
    <div className='feature__col2'>
      <img src={fea3} alt="" />
      <h3>Commitment to Continuous Learning</h3>
      <p>We believe that education is key to successful trading. Our platform is designed to help you learn and grow with each trade, providing you with the tools and resources you need to develop your trading skills over time.</p>
    </div>
    <br />
    <div className='feature__col2'>
      <img src={fea2} alt="" />
      <h3>Community-Driven Success 
      </h3>
      <p>Trading can be isolating, but it doesn't have to be. With MyTradeSignal, you're never alone. Our supportive community and one-on-one mentorship ensure you have the guidance and support you need to navigate the markets confidently. </p>
    </div>
    </div>
  </div>
</section>

<br /><br />
<section>

  <div className='feature__main'>
  <h2>App Screenshots </h2>
  <br />
    <div className='feature__row2'>
      <div className='feature__col3'>
        <img src={feb1} alt="" /> 
      </div>
      <br />
      <div className='feature__col3'>
        <img src="" alt="" />
        <h3>Dashboard Overview 
        </h3> 
        <p style={{textAlign:"left"}}>View your trading alerts, market insights, and educational snippets all in one place. Our user-friendly dashboard keeps you organized and focused, making trading more efficient. </p>
      </div>
    </div>
    <br />
    <div className='feature__row2'>
      <div className='feature__col3'>
        <img src={feb2} alt="" /> 
      </div>
      <br />
      <div className='feature__col3'>
        <img src="" alt="" />
        <h3>Real-Time Alerts Notification</h3> 
        <p>Receive immediate notifications of trading alerts directly to your phone. Never miss a trading opportunity with our instant alert system, designed to keep you informed wherever you are. </p>
      </div>
    </div>
    <br />
    <div className='feature__row2'>
    <div className='feature__col3'>
        <img src="" alt="" />
        <h3>Market Insights Page 
        </h3> 
        <p>Access detailed daily market insights to understand market movements. This page gives you a comprehensive view of the market trends and helps you align your trading strategies accordingly. </p>
      </div>
      <br />
      <div className='feature__col3'>
        <img src={feb3} alt="" /> 
      </div>
    </div>
    <br /><div className='feature__row2'>
    <div className='feature__col3'>
        <img src="" alt="" />
        <h3>Community Chat Feature</h3> 
        <p>Engage with other traders through our integrated chat feature. Share strategies, ask questions, and get real-time support from both fellow traders and our team of experts. </p>
      </div>

      <div className='feature__col3'>
        <img src={feb3} alt="" /> 
      </div>
    </div>
    <br />
    <div className='feature__row2'>
      <div className='feature__col3'>
        <img src={feb5} alt="" /> 
      </div>
      <br />
      <div className='feature__col3'>
        <img src="" alt="" />
        <h3>Mentorship Session Booking</h3> 
        <p>Easily schedule one-on-one sessions with trading mentors directly from the app. Our intuitive booking system allows you to choose times that fit your schedule, ensuring you get the personalized support you need. 

</p>
      </div>
    </div>
  </div>
</section>
<br /><br />
<div className='div__9a'>
  <div className='div__9__row'>
    <div className='div__9a__col'>
      <img src={bit}></img>
    </div>
    <div className='div__9a__col'>
      <h2>Subscribe our news</h2>
    <p>
    Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</p>
    <input type="Name" style={{padding:"10px"}} value="Enter your email" />
    <button style={{color:"black",fontFamily:"Nunito",padding:"10px",border:"1px solid white"}}>Submit</button> 
    </div>
  </div>
  </div>
  <br /><br />

    </div>
  )
}
export default Features;